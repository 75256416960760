import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import useWindowDimensions from '../helpers/WindowDimension'
import Dictionary from '../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'
import {stat} from 'fs'
import {
  MixedWidget49,
  MixedWidget5,
  SmartTile,
  SmartGasHealth,
} from '../../../_metronic/partials/widgets'

import SensorView3 from './SensorView3'
import {set} from 'immutable'

function Smartgas1(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [GasInfo, setGasInfo] = useState('')
  const [selected, setSelected] = useState('')
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])

  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    units: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
    factroyName: '',
    factroyCode: '',
    unitName: '',
    unitCode: '',
    packet: (props.match?.params?.code ?? 'dream') + '_group',
    Field: '',
    GasData: [],
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
    IsDataReceived: false,
  })

  useEffect(() => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + '/api/v1/sensor/dashbaord',
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          GasData: response.data.result,
        })
        setLoading(false)
      }
    })
  }, [])

  function funcsetGasInfo(obj) {
    setGasInfo(obj)
  }

  function getSensorList(param) {
    setSelected(param)
  }

  if (isloading) {
    return (
      <>
        <LoadingSentrum state={state} />
      </>
    )
  }

  return (
    <>
      <PageTitle code={props?.match?.params?.code} breadcrumbs={layout.breadCrumbs}>
        Smart Gas Overview
      </PageTitle>
      <>
        {GasInfo.length > 0 && (
          <>
            <SensorView3 funcsetGasInfo={funcsetGasInfo} code={GasInfo} />{' '}
          </>
        )}
        {GasInfo.length == 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-4'>
                <SmartGasHealth
                  className='card-xl-stretch mb-xl-8'
                  chartColor='warning'
                  chartHeight='150px'
                  name={'ddd'}
                  type='OFFLINE'
                  min='-19°C'
                  max='-25°C'
                  val={layout?.GasData?.normal?.length ?? 0}
                  Link={'#'}
                  data={'H'}
                  getSensorList={getSensorList}
                />
              </div>
              <div className='col-xl-4'>
                <SmartGasHealth
                  className='card-xl-stretch mb-xl-8'
                  chartColor='warning'
                  chartHeight='150px'
                  name={'ddd'}
                  type='OFFLINE'
                  min='-19°C'
                  max='-25°C'
                  val={layout?.GasData?.high?.length ?? 0}
                  Link={'#'}
                  data={'W'}
                  getSensorList={getSensorList}
                />
              </div>
              <div className='col-xl-4'>
                <SmartGasHealth
                  className='card-xl-stretch mb-xl-8'
                  chartColor='warning'
                  chartHeight='150px'
                  name={'ddd'}
                  type='OFFLINE'
                  min='-19°C'
                  max='-25°C'
                  val={layout?.GasData?.disable?.length ?? 0}
                  Link={'#'}
                  data={'D'}
                  getSensorList={getSensorList}
                />
              </div>
            </div>
            <br />
            {selected == 'H' && (
              <>
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-bold'>
                    <table>
                      <tr>
                        <td>
                          <h2 className='text-gray-800 fw-bolder'>
                            Healthy <div className='fs-6 text-gray-600'> Gas Tanks </div>
                          </h2>
                        </td>
                        <td>
                          <i
                            className='bi bi-arrow-right-circle text-mutes hover-text-danger'
                            style={{fontSize: '1.5rem', marginLeft: '10px'}}
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>{' '}
                <hr />
                <div className='row g-5 g-xl-8'>
                  {layout.GasData?.normal &&
                    layout.GasData?.normal.map((item, index) => (
                      <div className='col-xl-4'>
                        <SmartTile
                          className='card-xl-stretch mb-xl-8'
                          chartColor='warning'
                          chartHeight='150px'
                          name={'ddd'}
                          type='OFFLINE'
                          min='-19°C'
                          max='-25°C'
                          val={'11'}
                          Link={'#'}
                          data={JSON.stringify(item)}
                          funcsetGasInfo={funcsetGasInfo}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
            {selected == 'W' && (
              <>
                {' '}
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-bold'>
                    <table>
                      <tr>
                        <td>
                          <h2 className='text-gray-800 fw-bolder'>
                            Caution <div className='fs-6 text-gray-600'> Gas Tanks </div>
                          </h2>
                        </td>
                        <td>
                          <i
                            className='bi bi-arrow-right-circle text-mutes hover-text-danger'
                            style={{fontSize: '1.5rem', marginLeft: '10px'}}
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>{' '}
                <hr />
                <div className='row g-5 g-xl-8'>
                  {layout.GasData?.high &&
                    layout.GasData?.high.map((item, index) => (
                      <div className='col-xl-4'>
                        <SmartTile
                          className='card-xl-stretch mb-xl-8'
                          chartColor='warning'
                          chartHeight='150px'
                          name={'ddd'}
                          type='OFFLINE'
                          min='-19°C'
                          max='-25°C'
                          val={'11'}
                          Link={'#'}
                          data={JSON.stringify(item)}
                          funcsetGasInfo={funcsetGasInfo}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
            {selected == 'D' && (
              <>
                {' '}
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-bold'>
                    <table>
                      <tr>
                        <td>
                          <h2 className='text-gray-800 fw-bolder'>
                            Error <div className='fs-6 text-gray-600'> Gas Tanks </div>
                          </h2>
                        </td>
                        <td>
                          <i
                            className='bi bi-arrow-right-circle text-mutes hover-text-danger'
                            style={{fontSize: '1.5rem', marginLeft: '10px'}}
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>{' '}
                <hr />
                <div className='row g-5 g-xl-8'>
                  {layout.GasData?.disable &&
                    layout.GasData?.disable.map((item, index) => (
                      <div className='col-xl-4'>
                        <SmartTile
                          className='card-xl-stretch mb-xl-8'
                          chartColor='warning'
                          chartHeight='150px'
                          name={'ddd'}
                          type='OFFLINE'
                          min='-19°C'
                          max='-25°C'
                          val={'11'}
                          Link={'#'}
                          data={JSON.stringify(item)}
                          funcsetGasInfo={funcsetGasInfo}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  )
}

export default Smartgas1
