/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { link } from 'fs'
import { config } from 'process'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  name: string
  min: string
  max: string
  val: number
  Link: string
  type: string
  data: string
  getSensorList: any
}

const transformString = (str: any) => {
  // Remove "smartgas." part and split by ':'
  const parts = str.replace('smartgas.', '').split(':');

  // Join parts with '_', prefix first part with 'c'
  return str;// `c_${parts.join('_')}`;
};

const routeChange = (link: any) => {
  window.location.href = link;
  // alert('asa' + link)
}

const SmartGasHealth: React.FC<Props> = ({ className, chartColor, chartHeight, name, min, max, val, Link, type, data, getSensorList }) => {

  function getSensorListData2() {
    getSensorList(data)
  }

  var url = ""
  var name = ""
  if (data === "H") {
    url = "https://portal.smartgas.biz/media/bg/smartgas/normal.png";
    name = "Healthy"
  } else if (data === "W") {
    url = "https://portal.smartgas.biz/media/bg/smartgas/warning.png";
    name = "Caution"
  } else {
    url = "https://portal.smartgas.biz/media/bg/smartgas/error.png";
    name = "Error"
  }

  return <>
    <div className="card mb-4">
      <div className="card-body small-card-padding rounded">
        <div className="d-flex align-items-center flex-w">
          <div className="bg-light-success rounded p-2 p-md-2 p-lg-3 small-card-img ">
            <div className="symbol symbol-circle symbol-50px symbol-lg-75px">

              <img src={url} alt="" className="rounded-circle" style={{ objectFit: "cover" }} />
            </div></div><div className="d-flex flex-column w-100 text-start align-items-center ms-5">
            <div className="d-flex justify-content-between mt-2 w-100">
              <h1 className="d-flex align-items-center card-titles" style={{ fontWeight: 900 }}>{val}</h1>
            </div><div className="d-flex flex-column flex-1 w-100 ">
              <h1 className="card-title-sub fw-semibold text-gray-500 mb-2">{name}</h1>
            </div></div></div><div className="separator separator-dashed mt-4 mb-3"></div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex"><span className="text-muted fw-semibold fs-7">View Details</span></div>
          <a href="#" className="d-flex btn btn-icon  btn-active-color-primary btn-sm justify-content-end">
            <i onClick={(r) => getSensorListData2()} className="bi bi-arrow-right-circle text-mutes hover-text-danger" style={{ fontSize: "1.5rem" }}></i></a>
        </div></div></div>

  </>


  return (
    <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>



      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className={`flex-grow-1 card-p pb-0 `}>
          <h1>dadasdadas</h1>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', right: '20px', top: '15px' }}>
              <>
                <span style={{ fontSize: '13px', marginTop: "-7px", position: "absolute" }} className={`dot bg-info`}></span>
                <span style={{ fontSize: '13px', marginTop: '-20px', marginLeft: '-12px' }} className={`pulse-ring bg-info`}> </span>
              </>
            </div>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', fontSize: '12px', right: '20px', bottom: '5px' }}>
              <>
                <div className=' '></div>
              </>
            </div>
          </div>


        </div>
        {/* end::Stats */}


      </div>
      {/* end::Body */}
    </div >
  )
}


export { SmartGasHealth }
