/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { link } from 'fs'
import { config } from 'process'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  name: string
  min: string
  max: string
  val: number
  Link: string
  type: string
  data: string
  funcsetGasInfo: any
}

const transformString = (str: any) => {
  // Remove "smartgas." part and split by ':'
  const parts = str.replace('smartgas.', '').split(':');

  // Join parts with '_', prefix first part with 'c'
  return str;// `c_${parts.join('_')}`;
};

const routeChange = (link: any) => {
  window.location.href = link;
  // alert('asa' + link)
}

const SmartTile: React.FC<Props> = ({ className, chartColor, chartHeight, name, min, max, val, Link, type, data, funcsetGasInfo }) => {



  const chartRef = useRef<HTMLDivElement | null>(null)
  var color = ''

  var objList = JSON.parse(data);
  //"Pg":"smartgas.a4:c1:38:fa:92:a9"}
  //var Pg = objList.filter((item: any) => item.FieldName == "PG")[0]
  ////var obj = objList.filter((item: any) => item.FieldName == "MCU_TEMP")[0]
  var ncu_fw_ver = objList?.t_NCU_FW_VER
  var deviceName = objList?.GateWay;
  var imei = objList?.t_IMEI
  var BAT_VOL = objList.t_BAT_VOL
  ////var DATETIME = objList.filter((item: any) => item.FieldName == "DATETIME")[0]
  var GAS_METER = objList?.t_GAS_METER

  //var pr = ((GAS_METER.Value / 400.0) * 100); //
  var F_DATETIME = objList?.t_F_DATETIME
  //var CSQ = objList.filter((item: any) => item.FieldName == "CSQ")[0]
  var RSSI = objList?.t_RSSI ?? ""

  var TANK_SIZE = objList?.t_TANK_SIZE
  var GAS_PERCENT = objList?.t_GAS_PERCENT ?? 0
  var pr = GAS_PERCENT ?? 0
  var colurx = '#900C3F';
  if (pr > 70) {
    colurx = '#09932c';
  }
  else if (pr > 45) {
    colurx = '#FFC300'
  }
  else {
    colurx = '#900C3F';
  }

  return (
    <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>



      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className={`flex-grow-1 card-p pb-0 bg-${color}`}>
          <div className='d-flex flex-stack flex-wrap'>
            <table style={{ width: '100%' }} >
              <tr>
                <td>
                  <div className='me-1'>
                    <div style={{
                      backgroundColor: colurx,
                      width: '55px',
                      height: '80px',
                      float: 'left',
                      top: 0
                    }}>
                      <div style={{
                        backgroundColor: 'grey',

                        bottom: 0,
                        width: '100%',
                        height: (100 - pr) + '%',
                        //border-top: 6px solid #FFF;
                        boxSizing: 'border-box',
                        animation: 'grow 1.5s ease-out forwards',
                        transformOrigin: 'bottom'
                      }}></div>
                    </div>
                    <img style={{ width: "64px", position: 'absolute', left: '25px', top: '5px' }} src={toAbsoluteUrl('/media/img/gas.png')} alt='Footer' />
                  </div>
                </td>
                <td>
                  <div className='me-2'>

                    <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                      <div onClick={d => funcsetGasInfo('smartgas.' + transformString(imei))} > {deviceName ?? imei}  {/*JSON.stringify(objList)} */}</div>
                    </a>

                    <div className='text-muted fs-7 fw-bold'>Tank Level: <b>{GAS_METER} cm</b> | Size: <b>{TANK_SIZE ?? "--- lb"}</b></div>

                  </div>
                </td>
                <td  >
                  <div style={{ float: 'right', right: '0px', color: colurx }} className={`fw-bolder fs-3 `} >{pr}%</div>
                </td>
              </tr>
            </table>


            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', right: '20px', top: '15px' }}>
              <>
                <span style={{ fontSize: '13px', marginTop: "-7px", position: "absolute" }} className={`dot bg-info`}></span>
                <span style={{ fontSize: '13px', marginTop: '-20px', marginLeft: '-12px' }} className={`pulse-ring bg-info`}> </span>
              </>
            </div>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', fontSize: '12px', right: '20px', bottom: '5px' }}>
              <>
                <div className=' '>{objList?.Date}</div>
                V {ncu_fw_ver ?? "--"} <i className="fa fa-cog" aria-hidden="true"></i> Battery {BAT_VOL} <i className='fas fa-battery-half'></i> <i className="fa fa-signal"></i> {RSSI}
              </>
            </div>
          </div>


        </div>
        {/* end::Stats */}


      </div>
      {/* end::Body */}
    </div >
  )
}


export { SmartTile }
